.discount-card-container {
    padding: 10px 17px;
    border-radius: 7px;
    cursor: pointer;
}
.discount-card {
   flex: 1;
}


.discount-card__brand {
    color: #DC7302;
}

.discount-use {
    color: rgb(37, 90, 142);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 16px;
    width: 40px;
}

.custom-pad {
    padding: 7px 15px;

}