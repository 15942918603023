.profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}
.profile-header-box {
    border-bottom: 1px solid #D6D9DC;

}


.avator {
    display: block;
    background-color: #666666;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.4545455;
    text-align: center;
    text-transform: uppercase;
    -webkit-user-select: none;
    user-select: none;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.profile-filter-button--left  {
    border-left: 1px solid  #D6D6D6;
    border-top: 1px solid  #D6D6D6;
    border-bottom: 1px solid  #D6D6D6;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.profile-filter-button--right  {
    border-left: 1px solid  #D6D6D6;
    border-right: 1px solid  #D6D6D6;
    border-top: 1px solid  #D6D6D6;
    border-bottom: 1px solid  #D6D6D6;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btn-status-active {
    background: #D91400;
    color: white;
}

.edit-link {
    font-size: 15px;
    text-decoration: underline;
}