.store-input {
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 15px;
    padding: 0px 10px !important;

} 

.store-input--active {
    border: 1px solid var(--primary);

}


.store-input-label {
    margin: 10px 4px !important;

}


.store-file-btn {
    max-width: 350px;
    width: 350px;
    height: 40px;
    border-radius: 230px !important;
    overflow: hidden;
}



.store-image {
    margin: 10px 4px !important;

    font-size: 14px;
}

.store-image-container {
    display: flex;
    flex-direction: column;
    margin: 0px 5px;
}

.store-page {
    margin-top: 90px;
}

.m-grid-input {
    margin: 0px 35px;
}