.store-profile {
    border-left: 1px solid #D6D9DC;
    border-right: 1px solid #D6D9DC;
}


.icon-eye {
    font-size: 19px;
}
.btn-decline {
    --background: #ff5151 !important;
    --color: white !important;
}

.store-profile-card {
    display: flex;
    align-items: center;
    width: 100%;
}
.nl-1 {
    margin-left: -4px;
}


.btn-approved {
    --background: #27bb4d;
}