.back {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
    background-color: #F8F9FE;
    
}

.redeem-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.redeem-text {
    color: rgb(50, 50, 93);
        display: flex;
        flex-direction: column;
        align-items: center;
}

.swipable-button  {
    width: 100%;
    height: 58px;
    background: linear-gradient(
88deg
,#255a8e,#91ceec)!important;
    border-radius: 200px;
    padding: 2px;
    transition: background .6s;
    position: relative;
    display: flex;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    color: white;
    font-size: 23px;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
}


.swipe-icon {
    background: white;
    padding: 10px;
    border-radius: 100%;
    margin-left: 10px;
    cursor: pointer;


}

.Countdown  {
    margin: 0 auto  !important;
}

.card-discount-swipe > div {
    margin: 0 auto !important;
} 


.card-discount-swipe {
    padding: 20px 30px;
}


.swipable-button-deactive {
    filter: grayscale(100);
    font-size: 20px !important;
    font-weight: 500 !important;
    height: 45px;
}

.logged-in-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
    padding: 25px;
}