.success-root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
   text-align: center;
   height: 100%;
  }
  .success-root .new-button{
    text-transform: none; 
    margin-left: 20px;
  }
  .success-root .check-icon{
    font-size: 70px;
  }
  