.login {
  height: 100vh;
  background-color: #172b4d;
}

.header-login {
  display: flex;
  padding: 0px 20px;
  background-color: white;
}

.gredient {
  min-height: 33vh;
  background: linear-gradient(90deg, #255a8e, #91ceec) !important;
}

/* .separator {
    position: absolute;
    top: 320px;
    left: 0px;
    right: 0;
    width: 100%;
    height: 150px;
    transform: translateZ(0);
    overflow: hidden;
    pointer-events: none;
    transform: skew(
1deg
,
175deg
);
    background: linear-gradient(
90deg
 ,#255a8e,#91ceec)!important;
} */

/* Firebase UI */

.firebaseui-container {
  max-width: 720px !important;
  background-color: #f7fafc !important;
  padding: 10px;
}
.login-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  margin-top: -100px;
}

.login-error-text {
  color: #ff5555;
}

.signbox {
  flex: 1 !important;
}

.input-form {
  --background: #f7f7f7;
}

.input-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  text-align: left;
  margin: 0px;
  padding: 0px;
  display: block;
}

.sign-input {
  --background: #f7f7f7;
  border-radius: 10px;
}

.date-input > input {
  filter: brightness(0.5);
}
