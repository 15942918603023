.typeCard {
    width: 100px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
}
.typeCard-active {
    border: 3px solid #F7941D;
}


.typeText {
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 6px;
}

.icon-treats {
    --fa-primary-color: #cea46b;
    --fa-secondary-color: #ff8f8f;
}
