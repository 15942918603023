.home {
    height: 100vh;
}

.custom-margin {
    margin-left: -13px;
}

.home-search-bar{
    padding: 15px 0px;
}


.discount {
    height: 100%;
}


.home-loader > div {
    color: red  !important;
    border-color: red transparent transparent transparent !important;
}

.no-discount {
    color: white;
    margin: 10px;
    font-weight: 500;
    font-size: 18px;
    align-items: center;
    display: flex;
}

.btn-red-theme {
    --background: #D91400 ;
}