.home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
    padding-bottom: 8px;
    border-bottom: 1px solid #D6D6D6;
}
.header-store-profile {
    background-color: #4775A8 ;
    color: white ;
}

@media only  screen and (max-width: 600px) {
    .margin-res {
        margin-left: -6% !important;
    }  
  }

  @media only  screen and (max-width: 400px) {
    .margin-res {
        margin-left: -18% !important;
    }  
  }