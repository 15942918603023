.container-sign {
  padding: 0px 12px;
  max-width: 400px;
  width: 400px;
  height: 100%;
  background: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.signup-heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  margin-top: 15px;
}

.signup-subheading {
  color: rgb(118, 118, 118);
}

.input-form {
  --background: #f7f7f7;
}

.input-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  text-align: left;
  margin: 0px;
  padding: 0px;
  display: block;
}

.sign-input {
  --background: #f7f7f7;
  border-radius: 10px;
}

.btn-sign {
  width: 100%;
  --background: #dc2614;
  padding: 0px 8px;
}

.user-exist {
  font-weight: 600;
  color: rgb(236, 38, 20);
  font-size: 16px;
  display: flex;
  justify-content: center;
}
