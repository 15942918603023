.input-groups-custom {
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 15px;
    font-weight: 400;
}
.search-Input-field {
    padding: 10px 0;
}


.input-icons {

    margin-top: 0px;

}

.input-icon {
    padding: 10px 10px;
    border: 1px solid #eae2e2;
}

.input-icon-1 {
    padding: 10px 10px;


}

.input-groups-custom {
    margin-top: 15px;

}

.border-round-focus {
    border: 2px solid black;
}