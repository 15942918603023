.setting-card  {
    color: #30bced;
    border: 1px solid #dbdde5;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
}
.setting-card:hover {
    color: #5e72e4;
}
 .setting-profile-container {
    border: 1px solid #dbdde5;
    border-radius: 7px;
} 

.setting-profile-header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #255A8E;
    border-bottom: 1px solid #dbdde5;
    margin-bottom: 20px;
}
.setting-card-icon {
    font-size: 35px;
    color: currentColor;
}

.update-btn {
    display: flex;
    align-items: center;
    margin: 19px 15px;
}


