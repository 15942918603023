.d-flex {
    display: flex;
}


.margin-top-header {
    margin-top: 100px;
}

.h-100 {
    height: 100%;
}



.btn-red-cus {
    --background: #D91400;
}


.padding-bottom-120 {
    padding-bottom: 120px;
}

